import "core-js/es7/promise";
import "core-js/es6/number";
import "core-js/fn/symbol/iterator.js";
import "intersection-observer";

// Needed by Ag-grid table to correcty be rendered in IE11
Document.prototype.contains = function contains(node) {
    if (!(0 in arguments)) {
        throw new TypeError("1 argument is required");
    }

    do {
        if (this === node) {
            return true;
        }
    } while ((node = node && node.parentNode));

    return false;
};
